<template>
  <v-dialog v-model="dialog" max-width="500" scrollable>
    <v-card>
      <v-toolbar dark color="primary" flat dense>
        <v-toolbar-title>Всі</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <template v-if="Array.isArray(items)">
          <span v-for="(item, index) in items" :key="index">
            <router-link :to="{ name: instanceRouterName, params: { [instanceIdName]: item.id } }" class="text-decoration-none">
              <template>
                <span v-if="instanceName === 'device'">{{ getItemTitle(dictionary, item.type) }}{{ item.id }}</span>
                <span v-else>{{ item.name }}</span>
                <span v-if="index < items.length - 1"> | </span>
              </template>
            </router-link>
          </span>
        </template>
        <span v-else>{{ items }}</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Закрити </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConvertToDictionary from '@/mixins/convertToDictionary'

export default {
  name: 'AllItemsModal',

  mixins: [ConvertToDictionary],

  data: () => ({
    dialog: false,
    items: null,
    dictionary: null,
    instanceName: '',
  }),

  computed: {
    instanceRouterName() {
      return (this.instanceName + 'Card').replace(/^./, (char) => char.toUpperCase())
    },
    instanceIdName() {
      return this.instanceName + 'Id'
    },
  },

  methods: {
    showDialog(items, instanceName = '', dictionary = null) {
      this.items = items
      this.instanceName = instanceName
      this.dictionary = dictionary
      this.dialog = true
    },
  },
}
</script>
